@import 'Styles/Dansskolanentre/includes';

/**
*
* EventItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventItem.Base.module.scss';

.EventItem {
    $root: &;

    margin: 0 -12px 10px -12px;
    border-left-width: 0;
    border-right-width: 0;
    transition: border-color .2s;

    @include media(xs) {
        margin-left: 0;
        margin-right: 0;
        border-left-width: 2px;
        border-right-width: 2px;
    }

    @include media(sm) {
        margin-bottom: 12px;
    }

    @include media(md) {
        margin-bottom: 24px;
    }

    &__Container {
        background-color: $red-5;

        @include media(xs) {
            border-radius: 8px;
        }

        @include media(sm) {
            border-radius: 12px;
        }

        #{$root}--Alert & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__Logo {
        display: none;
    }

    &__Content {
        padding: 24px 20px 16px;

        @include media(sm) {
            width: calc(100% - 160px);
            padding: 32px;
        }

        @include media(md) {
            width: calc(100% - 200px);
        }
    }

    &__Alert {
        padding: 20px 20px 16px;
        background-color: $red-15;

        @include media(xs) {
            border-radius: 8px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        @include media(sm) {
            padding: 16px 30px;
            border-radius: 12px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    &__AlertWrapper {

        svg {
            fill: $primary-color;
            width: 20px;
            height: 20px;
        }
    }

    &__AlertMessage {
        margin-left: 10px;
        font-size: 1.4rem;
        font-weight: $medium;

        @include media(sm) {
            font-size: 1.6rem;
        }
    }

    &__Labels {
        display: none;
    }

    &__Type {
        display: none;
    }

    &__Title {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-right: 45px;
        margin-bottom: 7px;
        font-size: 2.2rem;
        font-weight: $bold;
        line-height: 1.37;
        transition: color .2s;

        @include media(sm) {
            font-size: 2.6rem;
            margin-bottom: 16px;
        }

        &::after {
            content: '';
            opacity: 0;
        }

        #{$root}--Hover:hover & {
            color: $primary-color;
            text-decoration: underline;

            &::after {
                opacity: 1;
            }

            @media(hover: none) {
                color: black;

                &::after {
                    opacity: 0;
                }
            }
        }

        #{$root}--Empty & {
            background: rgba(black, .03);
        }
    }

    &__Meta {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-right: 15px;
        margin-bottom: 12px;

        @include media(xs) {
            width: percentage(1/3);
        }

        @include media(sm) {
            margin-bottom: 7px;
        }

        @include media(md) {
            flex: 1 0 auto;
            width: 20%;
            margin-bottom: 0;
        }

        &--Null {
            display: none;

            @include media(md) {
                display: block;
            }
        }

        &--Price {
            width: 100%;
            position: relative;
            flex: 0 0 auto;
            padding-top: 20px;
            margin-top: 8px;
            margin-bottom: 0;
            font-weight: $medium;
            line-height: 1.2;
            display: flex;
            justify-content: center;
            border-top: 1px solid $red-15;

            @include media(xs) {
                width: 100%;
            }

            @include media(sm) {
                position: absolute;
                width: 160px;
                height: calc(100% - 48px);
                padding-top: 0;
                padding-left: 24px;
                margin: 0;
                top: 24px;
                bottom: 0;
                right: 0;
                border-top: none;
                border-left: 1px solid $red-15;
                transition: border .2s;
            }

            @include media(md) {
                width: 200px;
                padding-left: 48px;
            }
        }
    }

    &__MetaLabel {
        margin-bottom: 2px;

        #{$root}__Meta--Price & {
            @include media(sm) {
                margin-bottom: 4px;
            }
        }

        #{$root}--Empty & {
            position: relative;
            width: 50px;
            height: 13px;
            margin: 3px 0 4px 0;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                margin: 0;
                background: none;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);
                opacity: 1;

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 30px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 40px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 60px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 75px !important;
        }

        #{$root}--Empty #{$root}__Meta--Price & {
            width: 30px !important;
            margin-bottom: 7px;
        }
    }

    &__MetaLabelText {
        color: $grey-70;
        font-size: 1.2rem;
        font-weight: $medium;
        letter-spacing: .6px;
        line-height: 1.5;
        text-transform: uppercase;

        #{$root}__Meta--Price & {
            color: $grey-70;
        }
    }

    &__MetaText {
        font-size: 1.6rem;
        line-height: 1.5;

        #{$root}__Meta--Price & {
            font-size: 1.8rem;
            font-weight: $bold;
            line-height: 1.4;

            @include media(sm) {
                font-size: 2rem;
            }
        }

        #{$root}--Empty & {
            position: relative;
            width: 95px;
            height: 18px;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                margin: 0;
                background: none;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);
                opacity: 1;

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 70px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 60px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 115px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 110px !important;
        }

        #{$root}--Empty #{$root}__Meta--Price & {
            height: 24px;
            width: 90px !important;
        }
    }

}
