@import 'Styles/Base/includes';

.Root {
    $root: &;

    &--UpDown {
        transform: none !important;
        transform-origin: 50% 50% 0 !important;

        &#{$root}--Hidden {
            overflow: hidden;
        }
    }
}
