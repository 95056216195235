@import 'Styles/Base/includes';

/**
*
* CardCalendar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardCalendar {
    $root: &;

    position: relative;
    width: 100%;
    z-index: 0;

    &__Bg {
        position: absolute;
    }

    &__Container {
        width: 100%;
        position: relative;
    }

    &__Link {
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }

    &__DateContainer {
        margin-bottom: 24px;
    }

    &__Date {
        font-size: 1.4rem;
        text-transform: uppercase;

        &--Day {

            #{$root}--Empty & {
                width: 45px;
                height: 40px;

                @include media(md) {
                    width: 65px;
                    height: 60px;
                }
            }
        }

        &--Month {

            #{$root}--Empty & {
                width: 65px;
                height: 14px;

                @include media(md) {
                    width: 85px;
                    height: 16px;
                }
            }
        }

        #{$root}--Empty & {
            position: relative;
            background: rgba(black, .03);
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }
    }

    &__Title {
        margin-bottom: 20px;
        color: inherit;
        font-size: 2rem;
        line-height: 1.24;

        #{$root}--Empty & {
            position: relative;
            width: 90%;
            height: 48px;
            background: rgba(black, .03);
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }

            @include media(md) {
                height: 87px;
            }
        }
    }

    &__Info {
        display: flex;
        color: inherit;
        font-size: 1.4rem;
        line-height: 1.42;

        #{$root}--Empty & {
            position: relative;
            width: 125px;
            height: 20px;
            background: rgba(black, .03);
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }

            &:last-child {
                width: 90px;
            }
        }
    }

    &__Icon {
        margin-right: 10px;
        position: relative;
        top: 2px;

        svg {
            width: 16px;
            height: 16px;
            transition: fill .3s;
        }

        #{$root}--Empty & {
            display: none;
        }
    }
}
