@import 'Styles/Dansskolanentre/includes';

@import 'Menu.Base.module.scss';

.Menu {
    $root: &;

    &__Link {
        color: $text-color;
        font-size: 1.6rem;
        font-weight: $medium;

        #{$root}--Secondary & {
            color: $grey-70;
            font-size: 1.2rem;
            font-weight: $regular;
            letter-spacing: .2px;
            text-transform: uppercase;
        }

        &:after {
            background-color: $text-color;
        }
    }
}