@import 'Styles/Base/includes';

.Menu {
    $root: &;

    display: none;

    @include media(md) {
        display: block;
    }

    &--Primary {
        margin-left: 10px;
        margin-right: auto;

        @include media(lg) {
            margin-left: 30px;
        }
    }

    &--Application {
        display: block;
        margin-right: -12px;
    }

    &__List {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__Item {
        display: flex;
        align-items: center;
        height: 80px;
        padding-left: 12px;
        padding-right: 12px;
        text-align: center;

        #{$root}--Secondary & {
            height: 80px;
            padding-left: 8px;
            padding-right: 8px;
        }

        #{$root}--Application & {
            height: auto !important;
        }
    }

    &__Link {
        position: relative;
        display: inline-block;
        text-align: center;
        font-size: 1.5rem;
        text-decoration: none;
        white-space: nowrap;
        transition: opacity .15s;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            transition: opacity ease .2s;
            visibility: visible;
            opacity: 0;
        }

        &:hover::after {
            visibility: visible;
            opacity: 1;

            @media(hover: none) {
                visibility: visible;
                opacity: 0;
            }
        }

        #{$root}--Secondary & {
            font-size: 1.4rem;
        }

        #{$root}--IsMinimal & {
        }

        #{$root}__Item--Current & {
            &::after {
               visibility: visible;
               opacity: 1;
            }
        }

        #{$root}--Application & {
            padding-left: 0;
            padding-right: 0;
            font-size: 1.2rem;
            height: auto;
            line-height: 2;
            color: black;
            opacity: .45;
            transition: opacity .3s ease-in-out;

            &::before {
                opacity: 1 !important;
            }

            &:hover {
                opacity: .7;

                &::after {
                    opacity: 0;
                }
            }
        }
    }

    &__Loader {
        @include u-loader(15px);

        left: -20px;
        right: auto;
        display: none;
    }

}
