@import 'Styles/Pahlmans/includes';

/**
*
* EventItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventItem.Base.module.scss';

.EventItem {
    $root: &;

    position: relative;
    margin: 0 -12px 10px -12px;
    cursor: pointer;
    border: 1px solid palette(black, 15);
    border-left-width: 0;
    border-right-width: 0;

    @include media(xs) {
        margin-left: 0;
        margin-right: 0;
        border-left-width: 1px;
        border-right-width: 1px;
        border-radius: 4px;
    }

    @include media(sm) {
        margin-bottom: 7px;
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }

    &__Container {
        position: relative;
        background-color: white;

        @include media(xs) {
            border-radius: 4px;

            #{$root}--Alert & {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    &__Logo {
        position: absolute;
        top: 24px;
        right: 24px;
        max-width: 48px;
        height: auto !important;

        @include media(sm) {
            top: 30px;
            right: 36px;
        }

        #{$root}--HasPrice & {
            @include media(sm) {
                right: 204px;
            }

            @include media(md) {
                right: 244px;
            }
        }
    }

    &__Content {
        padding: 25px 20px 16px;

        #{$root}--HasPrice & {
            @include media(sm) {
                width: calc(100% - 184px);
            }

            @include media(md) {
                width: calc(100% - 224px);
            }
        }
    }

    &__Alert {
        padding: 16px 20px 16px;
        border-top: 1px solid palette(black, 15);
        background-color: white;
        border-radius: 0 0 4px 4px;
    }

    &__AlertWrapper {
        display: flex;
        align-items: center;

        svg {
            fill: $primary-color;
            width: 20px;
            height: 20px;
        }
    }

    &__AlertMessage {
        margin-left: 10px;
        font-size: 1.4rem;
        font-weight: $bold;
    }

    &__Labels {
        display: none;
    }

    &__Type {
        display: none;
    }

    &__Title {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-right: 45px;
        margin-bottom: 20px;
        transition: color .2s;

        @include media(lg) {
            font-size: 3.2rem;
        }

        #{$root}--HasLogo & {
            padding-right: 64px;

            @include media(sm) {
                padding-right: 80px;
            }
        }

        #{$root}--Hover:hover & {
            color: $primary-color;
            text-decoration: underline;

            @media(hover: none) {
                color: $heading-color;
                text-decoration: none;
            }
        }

        #{$root}--Empty & {
            background: rgba(black, .03);
        }
    }

    &__Meta {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-right: 15px;
        margin-bottom: 12px;

        @include media(xs) {
            width: percentage(1/3);
        }

        @include media(sm) {
            margin-bottom: 7px;
        }

        @include media(md) {
            flex: 1 0 auto;
            width: 20%;
        }

        &--Price {
            width: 100%;
            position: relative;
            flex: 0 0 auto;
            padding-top: 20px;
            margin-top: 15px;
            font-weight: $medium;
            line-height: 1.2;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -20px;
                right: -20px;
                height: 1px;
                background-color: $primary-color;
                transition: background .2s;
            }

            @include media(xs) {
                width: 100%;
            }

            @include media(sm) {
                position: absolute;
                justify-content: center;
                align-items: left;
                width: 160px;
                padding: 0;
                margin: 0;
                top: 0;
                bottom: 0;
                right: 0;
                transition: border .2s;

                &::before {
                    content:'';
                    height: calc(100% - 60px);
                    top: 30px;
                    left: -24px;
                    width: 3px;
                    background-color: $primary-color;
                }
            }

            @include media(md) {
                width: 200px;
            }
        }
    }

    &__MetaLabel {
        display: inline-block;

        #{$root}--Empty & {
            position: relative;
            width: 50px;
            height: 13px;
            margin: 3px 0 4px 0;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                margin: 0;
                background: none;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);
                opacity: 1;

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 30px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 40px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 60px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 75px !important;
        }

        #{$root}--Empty #{$root}__Meta--Price & {
            width: 30px !important;
            margin-bottom: 7px;
        }
    }

    &__MetaLabelText {
        display: inline-block;
        color: $primary-color;
        font-size: 1.1rem;
        font-weight: $bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1.2;

        #{$root}__Meta--Price & {
            color: $primary-color;
        }
    }

    &__MetaText {
        line-height: 1.2;
        font-weight: $normal;

        #{$root}__Meta--Price & {
            font-size: 2.4rem;
        }

        #{$root}--Empty & {
            position: relative;
            width: 95px;
            height: 18px;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                margin: 0;
                background: none;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);
                opacity: 1;

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 70px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 60px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 115px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 110px !important;
        }

        #{$root}--Empty #{$root}__Meta--Price & {
            height: 24px;
            width: 90px !important;
        }
    }
}
