@import 'Styles/Base/includes';

/**
*
* EventList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventList {
    $root: &;

    &__ResultText {
        text-align: center;
        transition: opacity .2s;

        span {
            display: none;
        }

        &--Keyword {
            :global(span.resultKeyword) {
                display: inline;
            }
        }

        &--City {
            :global(span.resultCity) {
                display: inline;

                em {
                    text-transform: capitalize;
                }
            }
        }

        #{$root}--Loading & {
            opacity: 0;
        }
    }

    &__Sort {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        transition: opacity .2s;

        @include media(sm) {
            font-size: 1.4rem;
        }

        #{$root}--Loading & {
            opacity: 0;
        }
    }

    &__SortLabel {
        font-weight: $bold;
    }

    &__SortContainer {
        display: flex;

        svg {
            align-self: center;
        }

        @include media(sm) {
            font-size: 1.4rem;
        }
    }

    &__SortItem {
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        -webkit-appearance: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__NoResult {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    &__NoResultTitle {
        margin-bottom: 5px;
    }
}
