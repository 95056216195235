@import 'Styles/Pahlmans/includes';

/**
*
* EventList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventList.Base.module.scss';

.EventList {
    $root: &;

    background: palette(black, 3);

    &--GlobalSearch {
        background: transparent;
    }

    &__Container {
        @include u-wrap(article);

        #{$root}--GlobalSearch & {
            padding: 0 !important;
        }
    }

    &__Result {
        padding-bottom: 20px;

        @include media(sm) {
            padding-bottom: 30px;
        }
    }

    &__Header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2rem;
        font-weight: $medium;

        @include media(sm) {
            align-items: baseline;
            font-size: 1.4rem;
        }
    }

    &__ResultText {

        em {
            font-weight: $black;
        }
    }

    &__Sort {
        align-items: flex-start;
        padding-top: 21px;
        height: 60px;
        z-index: z()+1;
    }

    &__SortLabel {
        margin-right: 2px;

        @include media(sm) {
            margin-right: 0;
        }
    }

    &__SortContainer {
        flex-direction: column;
        position: relative;
        top: -11px;
        border: 1px solid transparent;

        @include media(sm) {
            flex-direction: row;
            top: auto;
            border: none;
            background: none;
        }

        #{$root}__Sort--Active & {
          //  border: 1px solid palette(black, 15);
            background: white;
            z-index: get-default($z-index);

            @include media(sm) {
                border: none;
                background: none;
            }
        }
    }

    &__SortItem {
        height: 0px;
        padding: 0 0 0 8px;
        color: black;
        font-weight: $semibold;
        opacity: 0;

        @include media(sm) {
            height: auto;
            padding: 0;
            margin-left: 12px;
            opacity: 1;
        }

        &::after {
            content: '';
            position: relative;
            top: -2px;
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-left: 8px;
            background: transparent;
            border-top: 2px solid black;
            border-right: 2px solid black;
            opacity: 0;
            transform: rotate(135deg);

            @include media(sm) {
                display: none;
            }
        }

        #{$root}__Sort--Active & {
            height: auto;
            padding: 10px 0 10px 8px;
            opacity: 1;

            &::after {
                opacity: 0;
            }

            @include media(sm) {
                padding: 0;
            }
        }

        &--Active {
            height: auto;
            padding: 10px 0 10px 8px;
            opacity: 1;

            &::after {
                opacity: 1;
            }

            @include media(sm) {
                padding: 0;
                text-decoration: underline;
            }
        }
    }

    &__NoResult {
        padding: 30px 15px 60px;
        font-size: 1.6rem;

        @include media(md) {
            height: 268px;
            padding: 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultBorder {
        display: none;
    }

    &__NoResultTitle {
        font-size: 2rem;
        font-weight: $black;

        @include media(md) {
            font-size: 2.4rem;
        }
    }

    &__NoResultText {
        padding: 0;
        margin: 0 auto;
        max-width: 524px;
        color: palette(black, 86);
        font-size: 2.4rem;
        text-align: center;
        font-weight: $medium;
        font-family: $font-heading;

        @include media(sm) {
            font-size: 3.2rem;
        }

        em {
            font-weight: $black;
        }
    }

    &__NoResultFlag {
        display: none;
    }
}
