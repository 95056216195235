@import 'Styles/Kulturama/includes';

/**
*
* EventItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventItem.Base.module.scss';

.EventItem {
    $root: &;

    position: relative;
    margin: 0 -12px 10px -12px;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    border-left-width: 0;
    border-right-width: 0;
    transition: border-color .2s;

    @include media(xs) {
        margin-left: 0;
        margin-right: 0;
        border-left-width: 1px;
        border-right-width: 1px;
        border-radius: 4px;
    }

    @include media(sm) {
        margin-bottom: 7px;
    }

    &--Hover:hover {
        border-color: #d5d5d5;

        @media(hover: none) {
            border-color: #e7e7e7;
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z('EventItem');
    }

    &__Container {
        position: relative;
        background-color: white;

        @include media(xs) {
            border-radius: 4px;
        }
    }

    &__Logo {
        display: none;
    }

    &__Content {
        padding: 20px 20px 16px;
        width: 100%;

        @include media(sm) {
            width: calc(100% - 130px);
            padding: 22px 30px 24px;
        }

        @include media(md) {
            width: calc(100% - 170px);
        }
    }

    &__Labels {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 7px;

        #{$root}--Empty & {
            position: relative;
            width: 50px;
            height: 21px;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }
    }

    &__Type {
        margin-right: 10px;
        margin-bottom: 7px;
        color: palette(pink);
        font-size: 1.4rem;
        font-weight: $medium;
        line-height: 1.4;

        &--Label {
            color: palette(red);
            font-weight: $bold;
        }

        &--Tag {
            margin-top: -1px;
            padding: 3px 10px;
            color: white;
            font-size: 1.2rem;
            text-transform: uppercase;
            white-space: nowrap;
            border-radius: 3px;
            background: palette(pink);
        }
    }

    &__Title {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-right: 45px;
        margin-bottom: 7px;
        font-size: 2.4rem;
        font-weight: $medium;
        line-height: 1;
        transition: color .2s;

        &::after {
            content: '';
            opacity: 0;
        }

        #{$root}--Hover &::after {
            content: '';
            display: inline-block;
            position: relative;
            left: 10px;
            top: -2px;
            width: 17px;
            height: 14px;
            margin-right: -15px;
            background: url('../#{$basepath}img/bg-icons/kulturama/arrow--pink.svg') no-repeat;
            background-size: contain;
            opacity: 0;
            transition: opacity .2s;
        }

        #{$root}--Hover:hover & {
            color: palette(pink);

            &::after {
                opacity: 1;
            }

            @media(hover: none) {
                color: black;

                &::after {
                    opacity: 0;
                }
            }
        }

        #{$root}--Empty & {
            background: rgba(black, .03);
        }
    }

    &__Meta {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-right: 15px;
        margin-bottom: 12px;

        @include media(xs) {
            width: percentage(1/3);
        }

        @include media(sm) {
            margin-bottom: 7px;
        }

        @include media(md) {
            flex: 1 0 auto;
            width: 16.6%;
        }

        &--Null {
            display: none;

            @include media(md) {
                display: block;
            }
        }

        &--Price {
            width: 100%;
            position: relative;
            flex: 0 0 auto;
            padding-top: 20px;
            margin-top: 15px;
            font-weight: $medium;
            line-height: 1.2;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -20px;
                right: -20px;
                height: 1px;
                background: #e7e7e7;
                transition: background .2s;
            }

            @include media(xs) {
                width: 100%;
            }

            @include media(sm) {
                position: absolute;
                justify-content: center;
                align-items: center;
                width: 160px;
                max-height: 100%;
                padding: 0;
                margin: 0;
                top: 0;
                bottom: 0;
                right: 0;
                border-left: 1px solid #e7e7e7;
                transition: border .2s;

                &::before {
                    display: none;
                }
            }

            @include media(md) {
                width: 200px;
            }

            #{$root}--Hover:hover & {
                &::before {
                    background: #d5d5d5;
                }

                @include media(sm) {
                    border-color: #d5d5d5;
                }

                @media(hover: none) {
                    &::before {
                        background: #e7e7e7;
                    }

                    @include media(sm) {
                        border-color: #e7e7e7;
                    }
                }
            }
        }
    }

    &__MetaLabel {
        #{$root}--Empty & {
            position: relative;
            width: 50px;
            height: 13px;
            margin: 3px 0 4px 0;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                margin: 0;
                background: none;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);
                opacity: 1;

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 30px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 40px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 60px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 75px !important;
        }

        #{$root}--Empty #{$root}__Meta--Price & {
            width: 30px !important;
            margin-bottom: 7px;
        }
    }

    &__MetaLabelText {
        color: palette(black, 50);
        font-size: 1.1rem;
        font-weight: $medium;
        letter-spacing: 1px;
        text-transform: uppercase;

        #{$root}__Meta--Price & {
            color: palette(pink);
        }
    }

    &__MetaText {
        line-height: 1;

        #{$root}__Meta--Price & {
            font-size: 2.4rem;
        }

        #{$root}--Empty & {
            position: relative;
            width: 95px;
            height: 18px;
            background: rgba(black, .03);
            transition: opacity .4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                margin: 0;
                background: none;
                @include gradient-horizontal-three-colors(transparent, rgba(white, .3), 50%, transparent);
                opacity: 1;

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 70px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 60px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 115px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 110px !important;
        }

        #{$root}--Empty #{$root}__Meta--Price & {
            height: 24px;
            width: 90px !important;
        }
    }

    &__Alert {
        padding: 20px 20px 16px;
        border-top: 1px solid palette(black, 11);
        background-color: white;
        border-radius: 0 0 4px 4px;

        @include media(sm) {
            padding: 16px 30px;
        }
    }

    &__AlertWrapper {
        display: flex;
        align-items: center;

        svg {
            fill: palette(pink);
            width: 20px;
            height: 20px;
        }
    }

    &__AlertMessage {
        margin-left: 10px;
        font-size: 1.4rem;
        font-weight: $medium;

        @include media(sm) {
            font-size: 1.6rem;
        }
    }

}
