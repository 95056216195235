@import 'Styles/Kulturama/includes';

/**
*
* CardCalendar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardCalendar.Base.module.scss';

.CardCalendar {
    $root: &;

    background-color: white;
    border-color: white;
    transition: background-color .3s, color .3s;

    &--Hover {
        &#{$root}--White {
            &:hover {
                color: white;
                border-color: palette(pink);
                background-color: palette(pink);

                @media(hover: none) {
                    color: black;
                    border-color: white;
                    background-color: white;
                }
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        border: 32px solid transparent;
        border-left-width: 0;
        border-bottom-width: 0;
        border-top-color: inherit;
        transition: all .3s;
    }

    &--Red {
        border-color: palette(red);
    }

    &--Pink {
        border-color: palette(pink);
    }

    &--Yellow {
        border-color: palette(yellow);
    }

    &--Purple {
        border-color: palette(purple);
    }

    &--Red,
    &--Pink,
    &--Yellow,
    &--Purple {
        color: palette(white);
        background-color: transparent;

        // For covering a space between bottom tab and skewed area on animation
        &::before {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 32px;
            height: 0px;
            left: 0;
            border-top: 32px solid;
            border-color: inherit;

            @media(hover: none) {
                bottom: 0;
                width: calc(100% - 32px);
            }
        }

        &::before,
        &::after {
            transition: all .3s .3s;
        }

        &#{$root}--Hover:hover {
            &::after {
                border-width: 0px;
                left: 0;

                @media(hover: none) {
                    border-top-width: 32px;
                    border-right-width: 32px;
                }
            }
            &::before {
                bottom: 0px;

                @media(hover: none) {
                    bottom: 0;
                    width: calc(100% - 32px);
                }
            }

            &::before,
            &::after {
                transition: all .3s 0s;
            }
        }
    }

    &__Bg {
        top: 0;
        bottom: 32px;
        left: 0;
        right: 0;

        // Delay skew animation on hover in so bottom grow appears first and then skew
        // And when hover out delay bottom so skew animates first
        &:before,
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            content: '';
            display: block;
            height: 60%;
            z-index: -1;
            transform: translate3d(0, 0, 0) skewY(0deg);
            background: inherit;
            transition: bottom .3s .3s cubic-bezier(.20,1,.22,1), transform .3s 0s cubic-bezier(.20,1,.22,1);

            #{$root}--Hover:hover & {
                transform: skewY(4deg);
                transition: bottom .3s 0s, transform .3s .3s cubic-bezier(.20,1,.22,1);

                @media(hover: none) {
                    transform: skewY(0deg);
                }
            }
        }

        &:before {
            top: 0;
            transform-origin: top right;
        }

        &:after {
            bottom: 0px;
            transform-origin: top left;

            #{$root}--Hover:hover & {
                bottom: -32px;

                @media(hover: none) {
                    bottom: 0px;
                }
            }
        }

        #{$root}--Red & {
            background-color: palette(red);
        }

        #{$root}--Yellow & {
            background-color: palette(yellow);
        }

        #{$root}--Pink & {
            background-color: palette(pink);
        }

        #{$root}--Purple & {
            background-color: palette(purple);
        }
    }

    &__Container {
        padding: 40px 18px;
    }

    &__DateContainer {
        color: palette(pink);
        transition: color .3s;

        &:after {
            content: '';
            display: block;
            width: 35px;
            height: 2px;
            margin-top: 24px;
            background-color: palette(black, 10);
        }

        #{$root}--Red &,
        #{$root}--Pink &,
        #{$root}--Yellow &,
        #{$root}--Purple & {
            color: white;
        }

        #{$root}--Red#{$root}--Hover:hover &,
        #{$root}--Pink#{$root}--Hover:hover &,
        #{$root}--Yellow#{$root}--Hover:hover &,
        #{$root}--Purple#{$root}--Hover:hover & {
            color: white;

            @media(hover: none) {
                color: white;
            }
        }

        #{$root}--White#{$root}--Hover:hover & {
            color: white;

            @media(hover: none) {
                color: palette(pink);
            }
        }
    }

    &__Date {
        line-height: 1;

        &--Day {
            margin-bottom: 10px;
            font-size: 4rem;
            font-weight: $bold;

            @include media(md) {
                font-size: 6rem;
            }
        }

        &--Month {
            font-size: 1.4rem;
            font-weight: $medium;
            letter-spacing: 1px;

            @include media(md) {
                font-size: 1.6rem;
            }
        }
    }

    &__Title {

        @include media(md) {
            font-size: 2.4rem;
        }
    }

    &__Info {
        margin-bottom: 4px;
    }

    &__Icon {

        svg {
            fill: #b1b1b1;

            #{$root}--Red &,
            #{$root}--Pink &,
            #{$root}--Yellow &,
            #{$root}--Purple & {
                fill: white;

                @media(hover: none) {
                    fill: white;
                }
            }

            #{$root}--White#{$root}--Hover:hover & {
                fill: palette(white);

                @media(hover: none) {
                    fill: #b1b1b1;
                }
            }

            #{$root}__Info--location {
                width: 14px;
                height: 17px;
            }
        }
    }

    &__Price,
    &__Alert {
        display: none;
    }
}
