@import 'Styles/Medborgarskolan/includes';

/**
*
* EventItem
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventItem.Base.module.scss';

.EventItem {
    $root: &;

    position: relative;
    margin-bottom: 20px;
    cursor: pointer;

    @include media(sm) {
        margin-bottom: 24px;
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 8px;
        cursor: pointer;
        z-index: z('EventItem');
    }

    &__Container {
        position: relative;
        padding: 20px 16px 14px;
        background-color: white;
        border-radius: 8px;

        #{$root}--Alert & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include media(sm) {
            padding: 32px 32px 22px;
        }
    }

    &__Logo {
        display: none;
    }

    &__Content {
        position: relative;
    }

    &__Labels {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 0;
        margin-right: 90px;
        width: calc(100% - 80px);
        z-index: 1;

        @include media(sm) {
            margin-right: 0;
            width: calc(100% - 145px);
        }

        #{$root}--Empty & {
            position: relative;
            width: 50px;
            height: 21px;
            background: $blue-10;
            transition: opacity 0.4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(
                    transparent,
                    rgba(white, 0.3),
                    50%,
                    transparent
                );

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }
    }

    &__Type {
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 2px 10px;
        color: white;
        background-color: $purple-80;
        font-size: 1.2rem;
        font-weight: $bold;
        text-transform: uppercase;
        border-radius: 36px;
        line-height: 1.5;
        letter-spacing: 1px;

        &--Label {
            color: $system-red;
            font-weight: $bold;
        }

        &--Tag {
            margin-top: -1px;
            color: $blue;
            font-weight: $bold;
            text-transform: uppercase;
            white-space: nowrap;
            background: $grey-20;
        }

        @include media(sm) {
            padding: 1px 12px 2px;
            font-size: 1.4rem;
        }
    }

    &__Title {
        position: relative;
        width: calc(100% - 70px);
        margin-top: 2px;
        margin-bottom: 18px;
        padding-bottom: 0;
        font-size: 2.4rem;
        font-weight: $bold;
        color: $blue;
        line-height: 1.35;
        z-index: 1;
        word-wrap: break-word;
        hyphens: auto;

        #{$root}--Hover:hover & {
            text-decoration: underline;

            @media (hover: none) {
                text-decoration: none;
            }
        }

        @include media(sm) {
            width: calc(100% - 145px);
            margin-top: -2px;
            margin-bottom: 32px;
            font-size: 3.8rem;
        }

        #{$root}--Empty & {
            padding-bottom: 0;
            margin-bottom: 18px;
            background: $blue-10;
            transition: opacity 0.4s;
        }
    }

    &__Meta {
        display: inline-flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: $normal;
        color: $text-color;
        white-space: nowrap;

        @include media(sm) {
            font-size: 1.6rem;
        }

        &:not(.EventItem__Meta--Price) {
            width: 50%;
            max-width: 260px;
            margin-bottom: 10px;
            padding-right: 16px;

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                margin-right: 6px;
                background-position: center;
                background-repeat: no-repeat;
            }

            @include media(sm) {
                padding-right: 32px;

                &::before {
                    width: 24px;
                    height: 24px;
                }
            }

            @include media(md) {
                padding-right: 40px;
                width: auto;
            }
        }

        &::before {
            flex: 0 0 auto;
        }

        &--Null {
            display: none;
        }

        &--Location::before {
            background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/location--blue.svg');
        }

        &--Pace::before {
            background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/book--blue.svg');
        }

        &--Time::before {
            background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/time--blue.svg');
        }

        &--Start::before {
            background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/date--blue.svg');
        }

        &--Days::before {
            background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/days--blue.svg');
        }

        &--Price {
            position: absolute;
            top: -20px;
            right: -16px;
            display: block;
            width: auto;
            padding: 8px 16px;
            font-size: 1.8rem;
            font-family: $font-heading;
            font-weight: $bold;
            line-height: 1.33;
            color: $blue;
            z-index: 0;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 125px;
                height: 50px;
                border-top-right-radius: 8px;
                background: url('../#{$basepath}img/bg-icons/medborgarskolan/new/price-bg-top--white.svg')
                    no-repeat right top $purple-20;
                z-index: -1;

                @include media(sm) {
                    top: 0;
                    right: 0;
                    border-top-right-radius: 4px;
                    transform: scaleX(1.8) scaleY(1.7);
                    transform-origin: top right;
                }
            }

            @include media(sm) {
                top: -32px;
                right: -32px;
                min-width: 143px;
                padding: 15px 32px;
                font-size: 2.6rem;
                line-height: 1.39;
                text-align: right;
            }

            #{$root}--Empty & {
                width: 90px;

                @include media(sm) {
                    width: 142px;
                }
            }
        }

        &--Empty {
            &::before {
                display: none;
            }
        }
    }

    &__MetaText {
        #{$root}--Empty & {
            position: relative;
            width: 80px !important;
            height: 22px;
            background: $blue-10;
            transition: opacity 0.4s;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50px;
                @include gradient-horizontal-three-colors(
                    transparent,
                    rgba(white, 0.3),
                    50%,
                    transparent
                );

                :global {
                    animation: moveLoadingGradient 2s infinite;
                }
            }
        }

        #{$root}--Empty #{$root}__Meta:nth-child(2) & {
            width: 120px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(3) & {
            width: 100px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(4) & {
            width: 110px !important;
        }

        #{$root}--Empty #{$root}__Meta:nth-child(5) & {
            width: 90px !important;
        }
    }

    &__MetaLabelText {
        display: none;
    }

    &__Alert {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: $yellow-20;
        padding-left: 16px;
        padding-right: 16px;

        @include media(sm) {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    &__AlertWrapper {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        @include media(sm) {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('../#{$basepath}img/bg-icons/medborgarskolan/new/alert--blue.svg') no-repeat center center;
            background-size: contain;

            @include media(sm) {
                width: 24px;
                height: 24px;
            }
        }

        svg {
            display: none;
        }
    }

    &__AlertMessage {
        margin-left: 6px;
        font-size: 1.4rem;
        font-weight: $bold;
        color: $blue;

        @include media(sm) {
            font-size: 1.6rem;
        }
    }
}
