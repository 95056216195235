@import 'Styles/Medborgarskolan/includes';

/**
*
* EventList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EventList.Base.module.scss';

.EventList {
    $root: &;
    background-color: $blue-20;

    &__Container {
        @include u-wrap(article);

        #{$root}--GlobalSearch & {
            padding: 0 !important;
        }
    }

    &__AccordianTrigger {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 8px 20px 16px;
        margin-bottom: 20px;
        border-top: 8px solid $blue;
        border-radius: 8px;
        background: white;

        #{$root}--Loading & {
            opacity: 0;
        }

        @include media(xs) {
            padding-bottom: 8px;
            flex-direction: row;
        }

        @include media(sm) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @include media(md) {
            padding-top: 16px;
            padding-bottom: 16px;
            margin-bottom: 32px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0px 6px 9px black;
            opacity: 0;
            transition: opacity .2s ease-in;

            @include media(xs) {
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }

        #{$root}--Accordian & {
            cursor: pointer;

            &:hover::before {
                opacity: .3;

                @media(hover: none) {
                    opacity: 0;
                }
            }
        }
    }

    &__AccordianShow {
        flex: 0 0 auto;
        position: relative;
        padding-right: 32px;
        font-size: 1.4rem;
        font-weight: $bold;
        line-height: 1.35;

        @include media(sm) {
            font-size: 1.6rem;
        }
    }

    &__AccordianArrow {
        display: block;
        position: absolute;
        top: -1px;
        right: 0;
        width: 24px;
        height: 24px;
        margin: auto;
        background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg');
        background-size: contain;
        transform: rotate(90deg);
        transition: transform .2s ease-in;

        #{$root}--Open & {
            transform: rotate(-90deg);
        }
    }

    &__Result {
        margin-bottom: 32px;
    }

    &__Header {
        @include media(sm) {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }
    }

    &__ResultLabel {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 50px;
        padding: 24px 0 12px;
        color: $grey-90;
        font-size: 1.4rem;
        font-weight: $medium;
        text-align: center;

        @include media(sm) {
            padding: 0;
            border-bottom: 0;
            font-size: 1.4rem;
        }

        #{$root}__AccordianTrigger & {
            min-height: 0;
            padding: 8px 0;
            border: none;
        }
    }

    &__ResultText {

        em {
            font-weight: $bold;
        }

        span {
            white-space: nowrap;
        }

        &--NearbyCity {
            :global(span.resultCity) {
                em {
                    text-transform: none;
                }
            }
        }

        #{$root}__AccordianTrigger & {
            @include media(xs) {
                text-align: left;
            }
        }
    }

    &__ResultSearching {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity .2s;

        #{$root}--Loading & {
            opacity: 1;
        }
    }

    &__Sort {
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 24px;
        font-weight: $medium;
    }

    &__SortLabel {
        letter-spacing: 0.5px;
        margin-right: 16px;
    }

    &__SortContainer {

        svg {
            margin-right: 10px;
        }
    }

    &__SortItem {
        margin-right: 16px;
        color: $blue;
        font-weight: $medium;

        &:last-child {
            margin-right: 0;
        }

        &--Active {
            color: $blue;
            font-weight: $bold;
            text-decoration: underline;
        }
    }

    &__NoResult {
        padding: 30px 15px;
        margin: 15px 0 30px 0;
        font-size: 1.4rem;
        border-radius: 8px;
        background: white;

        @include media(md) {
            font-size: 1.6rem;
        }
    }

    &__NoResultText {
        em {
            font-weight: $bold;
        }
    }

    &__NoResultBorder {
        display: none;
    }

    &__NoResultTitle {
        font-size: 1.4rem;
        font-weight: $bold;
        display: none;

        @include media(md) {
            font-size: 1.6rem;
        }
    }

    &__NoResultFlag {
        display: none;
    }
}
